import { useLocaleContext } from "src/context/locale-context";
import classes from "./PassengerItemGroup.module.css";
import { CounterItem } from "src/components/UI/CounterItem/CounterItem";

export function PassengerItem({ type, counter, displayData }) {
  const { getStringRes } = useLocaleContext();
  return (
    <li id={type} className={classes.container}>
      <div className={classes.passenger}>
        <span>{getStringRes(displayData.value)}</span>
        <span>{getStringRes(displayData.description)}</span>
      </div>
      <CounterItem
        value={counter.count}
        canAdd={counter.canAdd}
        canRemove={counter.canRemove}
        onAdd={() => counter.onAdd(type)}
        onRemove={() => counter.onRemove(type)}
      />
    </li>
  );
}
