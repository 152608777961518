import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/UI/Button/Button";
import { NavLinkWithQuery } from "src/components/UI/NavLinkWithQuery/NavLinkWithQuery";

function GroupBookingRequestSent() {
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!state || !state.submitted) return <Navigate to=".." />;

  return (
    <main>
      <NavLinkWithQuery to="..">Back to booking</NavLinkWithQuery>
      <div>message sent</div>
      <Button
        onClick={() => {
          navigate("..");
        }}
        name={"Return to Booking"}
      />
    </main>
  );
}

export default GroupBookingRequestSent;
