import { useNavigate } from "react-router-dom";
import {
  ERROR_MESSAGES,
  ERROR_STATUS_MESSAGE,
  TRANSALTED_STRINGS,
} from "src/assets/lang/errorMessages";
import Footer from "src/components/shared/footer/Footer/Footer";
import Button from "src/components/UI/Button/Button";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import useSyncChildren from "src/hooks/useSyncChildren";

function PageNotFound() {
  const { isMobile } = useDeviceContext();
  const navigate = useNavigate();
  const { currentLocale, getStringRes } = useLocaleContext();
  const lang = currentLocale?.split("_")[0] || "en";
  const errMsg = ERROR_MESSAGES[lang].notfound;
  const { onLoad, isSynced } = useSyncChildren(["footer"]);

  const goToHomePage = () => {
    navigate(`/?lang=${lang}`);
  };

  return (
    <main style={{ display: isSynced ? "block" : "none" }}>
      <section id="page-not-found" className={`body ${isMobile ? "mob" : "dsk"}`}>
        <div
          style={{
            width: isMobile ? "18rem" : "24rem",
            height: isMobile ? "11rem" : "15rem",
            backgroundColor: "var(--ui-3)",
          }}></div>
        <h1>{ERROR_STATUS_MESSAGE[lang].notfound}</h1>
        <p>
          <span>{errMsg[0]}</span>
          <span>{errMsg[1]}</span>
        </p>

        <Button
          opts={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
          name={TRANSALTED_STRINGS[lang].homepage}
          onClick={goToHomePage}
        />
      </section>
      <Footer onLoad={onLoad} />
    </main>
  );
}

export default PageNotFound;
