import classes from "./DesktopMultiSearchForm.module.css";
import { Fragment, forwardRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { searchDataActions } from "src/store/search";
import { CABIN_CLASS_MAP } from "src/constants";
import SearchFormText from "src/components/features/search/SearchForm/elements/SearchFormText/SearchFormText";
import { calculateTotalPassengers, customLog } from "src/utils/utils";
import Button from "src/components/UI/Button/Button";
import { DesktopMultiSearchFormItem } from "./DesktopMultiSearchFormItem";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { useLocaleContext } from "src/context/locale-context";
import RoundPlusButton from "src/components/UI/Icon/RoundPlusButton";
import { useNavigation } from "react-router-dom";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { getPluralizedCaseCode } from "src/utils/trip-utils";

const MAX_TRIP_NUM = 6;

const DesktopMultiSearchForm = forwardRef(function DesktopMultiSearchForm(
  {
    onSearchFormClick,
    onCheckboxChange,
    checkValueAccom,
    onStartSearch,
    isButtonEnabled,
    isResultsPage,
  },
  ref
) {
  const dispatch = useDispatch();
  const { currentLocale, getStringRes } = useLocaleContext();
  const navigationState = useNavigation().state;
  const searchData = useSelector((state) => state.search, shallowEqual);
  const trips = searchData.trips;
  const canAddTrip = trips.length < MAX_TRIP_NUM;
  const passengerNum = calculateTotalPassengers(searchData.passengers);

  const cabinClass = searchData.cabinClass
    ? getStringRes(CABIN_CLASS_MAP[searchData.cabinClass]).toLowerCase()
    : getStringRes("sf.field.cabin_class_any");

  const passengerValue =
    `${passengerNum} ` +
    getStringRes(`sf.field.passenger_${getPluralizedCaseCode(passengerNum)}`) +
    ", " +
    cabinClass;

  const onAddFormHandler = () => {
    customLog("rerender on onAddFormHandler DISPATCH");
    dispatch(searchDataActions.addTrip());
  };

  const onRemoveFormHandler = (tripId) => {
    customLog("rerender on onRemoveFormHandler DISPATCH");
    dispatch(searchDataActions.removeTrip(tripId));
  };

  const onSwitchDirectionHandler = (tripId) => {
    const lang = currentLocale.split("_")[0].toUpperCase();
    customLog("rerender on onSwitchDirectionHandler DISPATCH");
    dispatch(searchDataActions.switchDestWithSrcLocation({ id: tripId, lang }));
  };

  return (
    <Fragment>
      <form
        name="main-search-form-multi"
        className={`${classes.multi} ${classes.desktop} ${
          isResultsPage ? classes.results : ""
        } ${isButtonEnabled ? "" : classes.noSFButton}`}>
        {trips.map((trip, i) => (
          <DesktopMultiSearchFormItem
            ref={ref}
            key={trip.id}
            trip={trip}
            tripNum={i + 1}
            onRemove={onRemoveFormHandler}
            onSwitchDirection={onSwitchDirectionHandler}
            onSearchFormClick={onSearchFormClick}
          />
        ))}
        <div className="grid-row-multi">
          <button
            type="button"
            className={`${classes.addButton} ${canAddTrip ? "" : classes.disabled}`}
            onClick={onAddFormHandler}
            disabled={!canAddTrip}>
            <RoundPlusButton isDisabled={!canAddTrip} />
            {getStringRes("sf.desktop.multi.button.add")}
          </button>
          <SearchFormText
            id="passengers"
            ref={ref}
            className={classes.passengers}
            value={passengerValue}
            label={getStringRes("sf.field.passengers")}
            onClick={() => onSearchFormClick("passengers")}
          />
          <Button
            disabled={!isButtonEnabled || navigationState === "loading"}
            name={
              !isResultsPage && navigationState === "loading" ? (
                <DotPulseLoader opacity={0.4} color="#fff" />
              ) : (
                getStringRes("sf.button.search")
              )
            }
            fontclass={classes.fontclass}
            onClick={isButtonEnabled ? onStartSearch : () => {}}
          />
        </div>
        {!isResultsPage && (
          <Checkbox
            id="include-accommodation"
            label={
              <span className={classes.checkbox2}>
                {getStringRes("sf.opt.accommodation")}
                <b>Booking.com</b>
              </span>
            }
            isChecked={checkValueAccom}
            onChange={onCheckboxChange}
          />
        )}
      </form>
    </Fragment>
  );
});

export default DesktopMultiSearchForm;
