import { motion } from "framer-motion";
import { Markup } from "interweave";
import { Fragment, useRef, useState } from "react";
import { createPortal } from "react-dom";
import fbLogoMobile from "src/assets/img/fbLogo2.png";
import manageIllustration from "src/assets/img/illustrations/manage.svg";
import instaLogoMobile from "src/assets/img/intagramLogo2.png";
import twitterLogoMobile from "src/assets/img/twitterLogo2.png";
import Button from "src/components/UI/Button/Button";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import Modal from "src/components/shared/modal/Modal/Modal";
import ExpandingNavList from "src/components/shared/navigation/ExpandingNavList/ExpandingNavList";
import SidePanel from "src/components/shared/panel/SidePanel/SidePanel";
import { LOCALE_TYPE, MODAL_ANIMATION_TIME, PROJECT_NAME } from "src/constants";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./GuestMenu.module.css";
import { guestMenuItems } from "./guestMenuItems";
import useClickAway from "src/hooks/useClickAway";
import { useAuth } from "src/context/auth-context";
import { useLocation, useNavigate } from "react-router-dom";

const portalElement = document.getElementById("sidebar");

export function SocialMediaGroup() {
  const logoList = {
    facebook: { alt: "facebook", img: fbLogoMobile },
    instagram: { alt: "instagram", img: instaLogoMobile },
    twitter: { alt: "twitter", img: twitterLogoMobile },
  };
  return (
    <div className={`${classes.container} ${classes.imageGroup}`}>
      <a href="/">
        <img width={15} alt={logoList.facebook.alt} src={logoList.facebook.img} />
      </a>
      <a href="/">
        <img width={15} alt={logoList.twitter.alt} src={logoList.twitter.img} />
      </a>
      <a href="/">
        <img width={15} alt={logoList.instagram.alt} src={logoList.instagram.img} />
      </a>
    </div>
  );
}

const LANGUAGE_STR_MAP = {
  [LOCALE_TYPE.GB]: "regional.language.english",
  [LOCALE_TYPE.LV]: "regional.language.latvian",
  [LOCALE_TYPE.LT]: "regional.language.lithuanian",
  [LOCALE_TYPE.EE]: "regional.language.estonian",
  [LOCALE_TYPE.RU]: "regional.language.russian",
};

function SideNavMenuContent({ onClose, onSignup, onLogin, mobile }) {
  const { currencySymbol, currency, currentLocale, getStringRes } = useLocaleContext();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();

  const goToDashboard = () => {
    onClose();
    navigate("/dashboard/trips" + search);
  };

  return (
    <Fragment>
      <button type="button" className={classes.close} onClick={onClose}>
        <XIconDesktop color={"var(--ui-5)"} />
      </button>
      <div
        className={`${classes.container} ${classes.guestMenu} ${
          mobile ? classes.mobile : ""
        }`}>
        <div className={classes.banner}>
          <img id="menu-manage-illustration" alt="managing things" src={manageIllustration} />
        </div>
        <div className={`${classes.container} ${classes.middle}`}>
          <p className={classes.container}>
            <span>
              <Markup
                content={getStringRes("main.nav.sidebar.text").replace(
                  "##PROJECT_NAME##",
                  PROJECT_NAME
                )}
              />
            </span>
          </p>
          {!isAuthenticated && (
            <>
              <Button
                name={getStringRes("res.modal.signin.button")}
                opts={{ fontSize: "var(--fmob-size-18px)" }}
                onClick={onLogin}
                primary
                wide
              />
              <motion.button
                whileTap={{ scale: 0.97, color: "var(--primary-link-color)" }}
                className={classes.textLink}
                onClick={onSignup}>
                {getStringRes("main.nav.account.create")}
              </motion.button>
            </>
          )}
          {isAuthenticated && (
            <Button
              name={getStringRes("button.general.dashboard")}
              opts={{ fontSize: "var(--fmob-size-18px)" }}
              onClick={goToDashboard}
              primary
              wide
            />
          )}
        </div>
        <div className={classes.infolinks}>
          {!mobile && (
            <span className={classes.container}>
              <img
                alt=""
                className={classes.icon}
                src={require(`src/assets/img/flags/${currentLocale}.png`)}
              />
              <span>
                {getStringRes(LANGUAGE_STR_MAP[currentLocale])} - {currency} {currencySymbol}
              </span>
            </span>
          )}
          <ExpandingNavList
            items={guestMenuItems.map((gmi) => {
              const { name, items } = gmi;
              return {
                name: getStringRes(name),
                items: items.map((i) => ({
                  name: getStringRes(i.name),
                  to: i.to,
                })),
              };
            })}
          />
        </div>
      </div>
    </Fragment>
  );
}

function MobileSideNavMenu({ onLogin, onSignup, onClose, isClosing }) {
  return (
    <Modal isClosing={isClosing} fullscreen>
      <SideNavMenuContent onSignup={onSignup} onLogin={onLogin} onClose={onClose} mobile />
    </Modal>
  );
}

function DesktopSideNavMenu({ onLogin, onSignup, onClose, isClosing }) {
  const panelRef = useRef();

  useClickAway(panelRef, onClose);

  const guestMenu = (
    <SidePanel ref={panelRef} isClosing={isClosing} opts={{ padding: "30px" }}>
      <SideNavMenuContent onSignup={onSignup} onLogin={onLogin} onClose={onClose} />
    </SidePanel>
  );

  return <Fragment>{createPortal(guestMenu, portalElement)}</Fragment>;
}

function SideNavMenu(props) {
  const { isMobile } = useDeviceContext();
  const [isMenuClosing, setIsMenuClosing] = useState(false);

  const onCloseHandler = () => {
    setTimeout(props.onClose, MODAL_ANIMATION_TIME);
    setIsMenuClosing(true);
  };

  const menuProps = {
    onLogin: props.onLogin,
    onSignup: props.onSignup,
    onClose: onCloseHandler,
    isClosing: isMenuClosing,
  };

  return (
    <Fragment>
      {isMobile ? <MobileSideNavMenu {...menuProps} /> : <DesktopSideNavMenu {...menuProps} />}
    </Fragment>
  );
}

export default SideNavMenu;
