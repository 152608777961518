import { useContext } from "react";
import { useDispatch } from "react-redux";
import { ModalElementContext } from "src/context/modal-element-context";
import { searchDataActions } from "src/store/search";
import { customLog } from "src/utils/utils";
import { FilteredLocationItem } from "./FilteredLocationItem";
import classes from "./LocationItemGroup.module.css";
import { useLocaleContext } from "src/context/locale-context";

export function FilteredLocationItemGroup({ items, isDeparture }) {
  const dispatch = useDispatch();
  const { onClose, targetTripId, isResultsModal, dispatchLocalState } =
    useContext(ModalElementContext);
  const { currentLocale, getStringRes } = useLocaleContext();

  const onSelectHandler = (location, isCity = false) => {
    customLog(location, isCity);
    let lang = currentLocale.split("_")[0].toUpperCase();
    if (isResultsModal) {
      dispatchLocalState({
        type: "UPDATE_TRIP",
        id: targetTripId,
        payload: {
          [isDeparture ? "srcLocation" : "destLocation"]: {
            city: location.city,
            iata: location.iata,
            cityIata: location.cityIata,
            isCity: isCity,
            names: location.names,
          },
        },
      });
      dispatch(
        searchDataActions.updateUserInputTrip({
          tripId: targetTripId,
          input: {
            [isDeparture ? "srcLocation" : "destLocation"]: location.names[lang],
            [isDeparture ? "srcIata" : "destIata"]: location.iata,
          },
        })
      );
    } else {
      const payload = {
        tripId: targetTripId,
        location: location,
        isCity: isCity,
        lang: lang,
      };
      if (isDeparture) {
        dispatch(searchDataActions.updateSrcLocation(payload));
      } else {
        dispatch(searchDataActions.updateDestLocation(payload));
      }
    }
    onClose();
  };

  return (
    <ul className={`${classes.cityList} ${classes.container} ${classes.filtered}`}>
      {items.map((item, i) => (
        <FilteredLocationItem
          key={item.cityIata}
          order={i}
          item={item}
          onSelectLocation={onSelectHandler}
        />
      ))}
    </ul>
  );
}
