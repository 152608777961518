import classes from "./SaleBanners.module.css";
import { Fragment, useEffect, useState } from "react";
import CarouselIndicatorGroup from "src/components/UI/CarouselIndicators/CarouselIndicatorGroup";
import { customLog } from "src/utils/utils";
import { useDeviceContext } from "src/context/device-context";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import { motion } from "framer-motion";
import { IconPhotoFilled } from "@tabler/icons-react";
import { staticApi } from "src/services/api";
import { useLocaleContext } from "src/context/locale-context";
import { nanoid } from "@reduxjs/toolkit";

function MobileSaleBanners({ image, currentItem, total, onSwipe, link }) {
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const minSwipeLength = 25;

  const onTouchStartHandler = (e) => {
    setTouchEndX(null);
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const onTouchMoveHandler = (e) => setTouchEndX(e.targetTouches[0].clientX);

  const onSwipeHandler = () => {
    const distance = touchStartX - touchEndX;
    const isLeftSwipe = distance > minSwipeLength;
    const isRightSwipe = distance < -minSwipeLength;
    if (isLeftSwipe || isRightSwipe) {
      const swipeDirection = isLeftSwipe ? "left" : "right";
      customLog(swipeDirection);
      onSwipe(swipeDirection);
    }
  };

  return (
    <Fragment>
      <div
        className={`${classes.saleImage} ${classes.container}`}
        onTouchStart={onTouchStartHandler}
        onTouchMove={onTouchMoveHandler}
        onTouchEnd={onSwipeHandler}>
        {image ? (
          <motion.a
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            style={{ pointerEvents: "none" }}
            href={link}>
            <img alt="sale-banner" src={image} />
          </motion.a>
        ) : (
          <div className={classes.imgError}>
            <div className={classes.frame}>
              <IconPhotoFilled size={"100%"} color="var(--ui-4)" />
            </div>
          </div>
        )}
      </div>
      <CarouselIndicatorGroup count={total} current={currentItem} />
    </Fragment>
  );
}

function DesktopSaleBanners({ items }) {
  return (
    <div
      style={{ pointerEvents: "none" }}
      className={`${classes.saleImageGroup} ${classes.container}`}>
      {items.map((item) =>
        item.image ? (
          <motion.a
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            key={item.id}
            href={item.link}>
            <img alt="sale-banner" src={item.image} />
          </motion.a>
        ) : (
          <div key={item.id} className={classes.imgError}>
            <div className={classes.frame}>
              <IconPhotoFilled size={"100%"} color="var(--ui-4)" />
            </div>
          </div>
        )
      )}
    </div>
  );
}

function SaleBanners() {
  const { isMobile } = useDeviceContext();
  const { currentLocale } = useLocaleContext();
  const [saleData, setSaleData] = useState(
    Array.from({ length: 3 }, () => ({ id: nanoid(5), image: null }))
  );
  const [currentBanner, setCurrentBanner] = useState(0);

  const onSwipeHandler = (direction) => {
    const indexChange = direction === "left" ? 1 : -1;
    let nextBanner = currentBanner + indexChange;
    if (nextBanner > 2) {
      nextBanner = 0;
    } else if (nextBanner < 0) {
      nextBanner = 2;
    }
    customLog("curr banner " + nextBanner);
    setCurrentBanner(nextBanner);
  };

  useEffect(() => {
    staticApi
      .getStaticBanners(currentLocale.split("_")[0])
      .then((res) => {
        setSaleData(res.data.map((item) => ({ image: item, id: nanoid(5), link: "/" })));
      })
      .catch(console.error);
  }, [currentLocale]);

  return (
    <section
      style={{ marginBottom: "2rem" }} //HACK: fix calendar dbl click handling
      className={`${!isMobile ? classes.desktop : classes.mobile}`}>
      {isMobile ? (
        <MobileSaleBanners
          image={saleData[currentBanner].image}
          link={saleData[currentBanner].link}
          currentItem={currentBanner}
          total={saleData.length}
          onSwipe={onSwipeHandler}
        />
      ) : (
        <PaddedView>
          <DesktopSaleBanners items={saleData} />
        </PaddedView>
      )}
    </section>
  );
}

export default SaleBanners;
