import classes from "./SingleSearchForm.module.css";
import SearchFormText from "src/components/features/search/SearchForm/elements/SearchFormText/SearchFormText";
import SwitchDirectionButton from "src/components/UI/SwitchDirectionButton/SwitchDirectionButton";
import { Fragment } from "react";
import { useLocaleContext } from "src/context/locale-context";

export function SearchFormTextReadOnlyInputMobile({
  tripId, values = { src: "", dest: "" }, onClick, onSwitchDirection,
}) {
  const { getStringRes } = useLocaleContext();
  
  return (
    <Fragment>
      <SearchFormText
        id="src-location"
        className={classes.src}
        value={values.src}
        label={getStringRes("sf.label.from")}
        onClick={() => onClick("src-location", tripId)} />
      <SwitchDirectionButton onClick={onSwitchDirection} />
      <SearchFormText
        id="dest-location"
        className={classes.dest}
        value={values.dest}
        label={getStringRes("sf.label.to")}
        onClick={() => onClick("dest-location", tripId)} />
    </Fragment>
  );
}
