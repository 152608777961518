import classes from "./SingleSearchForm.module.css";
import SwitchDirectionButton from "src/components/UI/SwitchDirectionButton/SwitchDirectionButton";
import { Fragment, forwardRef } from "react";
import { DesktopSearchFormInput } from "../SearchFormInput/DesktopSearchFormInput";
import { useLocaleContext } from "src/context/locale-context";

export const SearchFormTextInputDesktop = forwardRef(function SearchFormTextInputDesktop(
  {
    tripId,
    values = { src: "", dest: "", srcIata: "", destIata: "" },
    onClick,
    onSwitchDirection,
    onChangeInput,
  },
  ref
) {
  const { getStringRes } = useLocaleContext();
  const onClickInputHandler = (id) => {
    onClick(id, tripId);
  };

  return (
    <Fragment>
      <DesktopSearchFormInput
        ref={ref}
        id="src-location"
        value={values.src}
        iata={values.srcIata}
        label={getStringRes("sf.label.from")}
        onClick={(e) => onClickInputHandler("src-location")}
        onChangeInput={(input) => onChangeInput("src-location", input)}
        className={classes.src}
      />
      <SwitchDirectionButton onClick={onSwitchDirection} />
      <DesktopSearchFormInput
        ref={ref}
        id="dest-location"
        value={values.dest}
        iata={values.destIata}
        label={getStringRes("sf.label.to")}
        onClick={() => onClickInputHandler("dest-location")}
        onChangeInput={(input) => onChangeInput("dest-location", input)}
        className={classes.dest}
      />
    </Fragment>
  );
});
