import Card from "src/components/UI/Card/Card";
import classes from "./UserMenu.module.css";
import { useRef, useCallback } from "react";
import useClickAway from "src/hooks/useClickAway";
import { useAuth } from "src/context/auth-context";
import { useLocaleContext } from "src/context/locale-context";
import { UserAvatar } from "src/components/features/dashboard/UserAvatar/UserAvatar";
import { LinkWithQuery } from "src/components/UI/NavLinkWithQuery/NavLinkWithQuery";

const cardOpts = {
  paddingTop: "1.875rem",
  paddingBottom: "1.875rem",
  position: "absolute",
  top: "3.125rem",
  zIndex: "850",
};

function UserMenu(props) {
  const { user } = useAuth();
  const menuRef = useRef();
  const { getStringRes } = useLocaleContext();

  const onCloseHandler = useCallback(() => {
    props.onClose();
  }, [props]);

  useClickAway(menuRef, onCloseHandler);

  const menuItems = [
    { name: getStringRes("menu.user.trips"), to: "/dashboard/trips" },
    // { name: getStringRes("menu.user.passengers"), to: "/dashboard/passengers" },
    { name: getStringRes("menu.user.invoices"), to: "/dashboard/invoices" },
    // { name: getStringRes("menu.user.priceAlerts"), to: "/dashboard/alerts" },
    // { name: getStringRes("menu.user.referral"), to: "/dashboard/referral" },
    // { name: getStringRes("menu.user.preferences"), to: "/dashboard/preferences" },
  ];

  return (
    <Card ref={menuRef} opts={cardOpts} shadowLg>
      <ul className={`${classes.container} ${classes.menu}`}>
        {menuItems.map((item) => (
          <li key={item.name}>
            <LinkWithQuery to={item.to}>{item.name}</LinkWithQuery>
          </li>
        ))}
        <li className={classes.divider} />
        <li>
          <button className={classes.signout} onClick={props.onLogout}>
            {getStringRes("menu.user.signout")}
          </button>
        </li>
        <li
          className={`${classes.container} ${classes.user}`}
          style={{ order: menuItems.length }}>
          <div className={classes.miniIcon}>
            {user?.photoURL ? (
              <img
                style={{ borderRadius: "100%", maxHeight: 17 }}
                alt="user"
                src={user.photoURL}
              />
            ) : (
              <UserAvatar />
            )}
          </div>
          <span className={classes.name} title={user.email}>
            {user.email}
          </span>
        </li>
      </ul>
    </Card>
  );
}

export default UserMenu;
