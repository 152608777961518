import { IconHeart } from "@tabler/icons-react";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ButtonWithIcon from "src/components/UI/ButtonWithIcon/ButtonWithIcon";
import SideNavMenu from "src/components/shared/menu/GuestMenu/GuestMenu";
import UserMenu from "src/components/shared/menu/UserMenu/UserMenu";
import { useAuth } from "src/context/auth-context";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { useModalContext } from "src/context/modal-stack-context";
import { MODAL_TYPE } from "src/constants/modal-definitions";
import { customLog } from "src/utils/utils";
import classes from "../TopNavigation.module.css";
import {
  BurgerMenuIcon,
  FavoritedIcon,
  UserAnonymousIcon,
  UserAvatarIcon,
  UserURLPhoto,
} from "./MenuIcons";

export function TopRightMenu({ withHeartButton }) {
  const { isMobile } = useDeviceContext();
  const location = useLocation();
  const { currentLocale, getStringRes } = useLocaleContext();
  const { isAuthenticated, user, logout } = useAuth();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [isFavorited, setFavorited] = useState(false);
  const { openModal } = useModalContext();

  const IMG_RESIZE_FACTOR = isMobile ? 1.25 : 1;

  const closeMenus = () => {
    setMenuOpen(false);
    setSideMenuOpen(false);
  };

  useEffect(closeMenus, [location]);

  const loginHandler = () => {
    openModal(MODAL_TYPE.loginOptions);
    closeMenus();
  };

  const logoutHandler = () => {
    logout();
    closeMenus();
  };

  const signupHandler = () => {
    openModal(MODAL_TYPE.createAccount);
    closeMenus();
  };

  const showUserMenu = isAuthenticated && isMenuOpen;

  const onOpenUserMenu = () => {
    if (isMobile) {
      openModal(MODAL_TYPE.mobileUserMenu, {
        onClose: (callback) => {
          if (callback) callback();
        },
      });
    } else {
      setMenuOpen((prev) => !prev);
    }
  };

  const onClickFavoriteHandler = () => {
    setFavorited((prev) => !prev);
  };

  const onClickRegionalSettings = () => {
    customLog("regional settings clicked");
    openModal(MODAL_TYPE.regionalSettings);
  };

  const rightMenuItems = [
    {
      props: {
        imgEl: ({ imageClass }) => {
          return isFavorited ? <FavoritedIcon /> : <IconHeart size={20} stroke={2} />;
        },
        imgWidth: 23 * IMG_RESIZE_FACTOR,
        imgHeight: 22 * IMG_RESIZE_FACTOR,
      },
      onClick: onClickFavoriteHandler,
      name: "favorite",
    },
    {
      props: {
        img: require(`src/assets/img/flags/${currentLocale}.png`),
        imgWidth: 16 * IMG_RESIZE_FACTOR,
        imgHeight: 16 * IMG_RESIZE_FACTOR,
      },
      text: isMobile ? null : "EUR",
      name: "currency",
      onClick: onClickRegionalSettings,
    },
    {
      props: {
        imgEl: ({ imageClass }) => {
          return !isAuthenticated ? (
            <UserAnonymousIcon imageClass={imageClass} />
          ) : user.photoURL ? (
            <UserURLPhoto imageClass={imageClass} photoUrl={user.photoURL} />
          ) : (
            <UserAvatarIcon />
          );
        },
        className: "menu-login-icon-btn",
      },
      text: isAuthenticated
        ? isMobile
          ? null
          : user.displayName || getStringRes("main.term.traveler")
        : getStringRes("main.nav.login"),
      name: "user",
      onClick: isAuthenticated ? onOpenUserMenu : loginHandler,
    },
    {
      props: {
        imgEl: ({ imageClass }) => <BurgerMenuIcon imageClass={imageClass} />,
      },
      onClick: () => setSideMenuOpen(true),
      name: "menu",
    },
  ].splice(withHeartButton ? 0 : 1);

  return (
    <Fragment>
      {isSideMenuOpen && (
        <SideNavMenu
          onClose={() => setSideMenuOpen(false)}
          onLogin={loginHandler}
          onSignup={signupHandler}
        />
      )}
      <nav className={`${classes.menuRight} ${classes.container}`}>
        {rightMenuItems.map((menuItem) => (
          <ButtonWithIcon
            key={menuItem.name}
            name={menuItem.name}
            {...menuItem.props}
            onClick={menuItem.onClick}>
            {menuItem.text}
          </ButtonWithIcon>
        ))}
        {!isMobile && showUserMenu && (
          <UserMenu onClose={() => setMenuOpen(false)} onLogout={logoutHandler} />
        )}
      </nav>
    </Fragment>
  );
}
