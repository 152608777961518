import { motion } from "framer-motion";
import { Fragment, forwardRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SearchFormDate from "src/components/features/search/SearchForm/elements/SearchFormDate/SearchFormDate";
import SearchFormText from "src/components/features/search/SearchForm/elements/SearchFormText/SearchFormText";
import Button from "src/components/UI/Button/Button";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { CABIN_CLASS_MAP, TRIP_TYPE } from "src/constants";
import { useLocaleContext } from "src/context/locale-context";
import { searchDataActions } from "src/store/search";
import { calculateTotalPassengers, compareToValidatedValue, customLog } from "src/utils/utils";
import { SearchFormTextInputDesktop } from "./SearchFormTextInputDesktop";
import classes from "./SingleSearchForm.module.css";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { getPluralizedCaseCode } from "src/utils/trip-utils";

export const DesktopSingleSearchForm = forwardRef(function DesktopSingleSearchForm(
  {
    onSearchFormClick,
    onCheckboxChange,
    checkValueDirect,
    checkValueAccom,
    onStartSearch,
    isButtonEnabled,
    isResultsPage,
  },
  ref
) {
  const dispatch = useDispatch();
  const { currentLocale, getStringRes } = useLocaleContext();
  const navigationState = useNavigation().state;
  const lang = currentLocale.split("_")[0].toUpperCase();
  const searchData = useSelector((state) => state.search, shallowEqual);
  const isRoundTrip = searchData.tripType === TRIP_TYPE.roundtrip;
  const trip = searchData.trips[0];
  const passengerNum = calculateTotalPassengers(searchData.passengers);
  const typedUserInput = useSelector((state) =>
    state.search.userInput.trips.find((item) => item.id === trip.id)
  );
  const cabinClass = searchData.cabinClass
    ? getStringRes(CABIN_CLASS_MAP[searchData.cabinClass]).toLowerCase()
    : getStringRes("sf.field.cabin_class_any");
  const passengerValue =
    `${passengerNum} ` +
    getStringRes(`sf.field.passenger_${getPluralizedCaseCode(passengerNum)}`) +
    ", " +
    cabinClass;

  const onSwitchDirectionHandler = () => {
    customLog("rerender on onSwitchDirectionHandler SingleSearchForm DISPATCH");
    dispatch(searchDataActions.switchDestWithSrcLocation({ id: trip.id, lang }));
  };

  const onClearDateHandler = (id) => {
    const payload = { tripId: trip.id, date: "" };
    if (id === "start-date") {
      dispatch(searchDataActions.updateStartDate(payload));
    } else if (id === "end-date") {
      dispatch(searchDataActions.updateEndDate(payload));
      dispatch(searchDataActions.updateTripType(TRIP_TYPE.oneway));
    }
  };

  const onChangeTextInputHandler = (id, input) => {
    if (input) {
      const userInpPayload = {
        tripId: trip.id,
      };

      const tripDataPayload = {
        tripId: trip.id,
        location: { city: "", iata: "", cityIata: "", names: [] },
        isCity: false,
      };

      if (id === "src-location") {
        userInpPayload.input = { srcLocation: input, srcIata: "" };
        dispatch(searchDataActions.updateSrcLocation(tripDataPayload));
      }
      if (id === "dest-location") {
        userInpPayload.input = { destLocation: input, destIata: "" };
        dispatch(searchDataActions.updateDestLocation(tripDataPayload));
      }
      dispatch(searchDataActions.updateUserInputTrip(userInpPayload));
    } else {
      onClearTextInputHandler(id);
    }
  };

  const onClearTextInputHandler = (id) => {
    const payload = {
      tripId: trip.id,
      location: { city: "", iata: "", cityIata: "", names: [] },
      isCity: false,
    };
    if (id === "src-location") {
      dispatch(searchDataActions.updateSrcLocation(payload));
    } else if (id === "dest-location") {
      dispatch(searchDataActions.updateDestLocation(payload));
    }
  };

  const inputProps = {
    tripId: trip.id,
    onSwitchDirection: onSwitchDirectionHandler,
    values: {
      src: typedUserInput.srcLocation,
      dest: typedUserInput.destLocation,
      srcIata: compareToValidatedValue(
        typedUserInput.srcLocation,
        typedUserInput.srcIata,
        trip.srcLocation,
        lang
      )
        ? trip.srcLocation.iata
        : "",
      destIata: compareToValidatedValue(
        typedUserInput.destLocation,
        typedUserInput.destIata,
        trip.destLocation,
        lang
      )
        ? trip.destLocation.iata
        : "",
    },
  };

  const [doHideBtn, setHideBtn] = useState(!isButtonEnabled);
  const onBtnAnimationEnd = () => {
    setHideBtn((prev) => !prev);
  };

  return (
    <Fragment>
      <form
        name="main-search-form"
        className={`${classes.search} ${isRoundTrip ? classes.roundtrip : classes.oneway} ${
          classes.desktop
        } ${isResultsPage ? classes.results : ""} 
        ${isButtonEnabled ? "" : classes.noSFButton}`}>
        <div
          id="search-form-backdrop-shadow"
          className={isResultsPage ? "results-page" : ""}></div>
        <SearchFormTextInputDesktop
          ref={ref}
          {...inputProps}
          onClick={onSearchFormClick}
          onChangeInput={onChangeTextInputHandler}
        />
        <SearchFormDate
          ref={ref}
          id="start-date"
          className={classes.startDate}
          value={trip.startDate}
          label={getStringRes("sf.field.date.depart")}
          onClick={() => onSearchFormClick("start-date", trip.id)}
        />
        {isRoundTrip && (
          <SearchFormDate
            ref={ref}
            id="end-date"
            className={classes.endDate}
            value={trip.endDate}
            label={getStringRes("sf.field.date.return")}
            onClick={() => onSearchFormClick("end-date", trip.id)}
            onClear={onClearDateHandler}
          />
        )}
        <SearchFormText
          ref={ref}
          id="passengers"
          className={classes.passengers}
          value={passengerValue}
          label={getStringRes("sf.field.passengers")}
          onClick={() => onSearchFormClick("passengers")}
        />
        <div className={classes.searchBtn + " " + (isResultsPage ? "small-sf-button" : "")}>
          <motion.div
            initial={false}
            style={{ position: "absolute", width: "inherit" }}
            animate={{
              position: "absolute",
              opacity: isButtonEnabled ? 1 : 0,
              x: isButtonEnabled ? 0 : "9.5rem",
            }}
            onAnimationEnd={onBtnAnimationEnd}
            transition={{ duration: 0.5, delay: isButtonEnabled ? 0.5 : 0 }}>
            <Button
              disabled={!isButtonEnabled || navigationState === "loading"}
              name={
                !isResultsPage && navigationState === "loading" ? (
                  <DotPulseLoader opacity={0.4} color="#fff" />
                ) : (
                  getStringRes("sf.button.search")
                )
              }
              fontclass={`${classes.fontclass} ${doHideBtn ? classes.hideBtn : ""}`}
              onClick={isButtonEnabled ? onStartSearch : () => {}}
            />
          </motion.div>
        </div>
        {!isResultsPage && (
          <Fragment>
            <Checkbox
              id="direct-flights-check"
              label={
                <span className={classes.checkbox1}>{getStringRes("sf.opt.direct")}</span>
              }
              isChecked={checkValueDirect}
              onChange={onCheckboxChange}
              checkMarkColor="var(--checkbox-accent-color)"
              checkMarkThickness="var(--checkbox-mark-thickness)"
            />
            <Checkbox
              id="include-accommodation"
              label={
                <span className={classes.checkbox2}>
                  {getStringRes("sf.opt.accommodation")}
                  <b>Booking.com</b>
                </span>
              }
              isChecked={checkValueAccom}
              onChange={onCheckboxChange}
              checkMarkColor="var(--checkbox-accent-color)"
              checkMarkThickness="var(--checkbox-mark-thickness)"
            />
          </Fragment>
        )}
      </form>
    </Fragment>
  );
});
