import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { LOCALE_TYPE } from "src/constants";
import { localeApi } from "src/services/api";
import { customLog } from "src/utils/utils";
import { STRING_RES_KEYS_ALL } from "src/constants/string-res-map";
import {
  getSelectedLocale,
  markSessionIsReloading,
  markSessionReuseUnblocked,
  setSelectedLocale,
} from "src/utils/storage-utils";
import { useDispatch } from "react-redux";
import { searchDataActions } from "src/store/search";
import { useTenantContext } from "./tenant-context";
import { useLocation, useNavigate } from "react-router-dom";

const CURRENCY_SYMBOLS = {
  EUR: "€",
  USD: "$",
  PLN: "zł",
  GBP: "£",
  RUB: "₽",
};

const LANG_VALUES = {
  [LOCALE_TYPE.GB]: ["English", "Latvian", "Russian", "Lithuanian"],
  [LOCALE_TYPE.LV]: ["Angļu", "Latviešu", "Krievu", "Lietuviešu"],
  [LOCALE_TYPE.LT]: ["Anglų", "Latvių", "Rusų", "Lietuvių"],
  [LOCALE_TYPE.RU]: ["Английский", "Латышский", "Русский", "Литовский"],
};

const COUNTRY_VALUES = {
  [LOCALE_TYPE.GB]: ["Latvia"],
  [LOCALE_TYPE.LV]: ["Latvija"],
  [LOCALE_TYPE.LT]: ["Latvija"],
  [LOCALE_TYPE.RU]: ["Латвия"],
};

const LocaleContext = createContext({
  currentLocale: "",
  currency: "",
  currencySymbol: "",
  stringRes: {},
  setLocale: () => {},
  languages: [],
  countries: [],
  currencies: [],
});

const LANG_MAP = { en: "en_GB", lv: "lv_LV", ru: "ru_RU" };

function useLanguageQueryParam() {
  const navigate = useNavigate();
  const location = useLocation();
  const sparams = new URLSearchParams(location.search);
  const langParam = sparams.get("lang");

  const onChangeLocale = useCallback(
    (locale) => {
      console.log("onChangeLocale method");
      const lang = locale.split("_")[0];
      console.log("lang", lang);
      let sq = new URLSearchParams(location.search);
      sq.set("lang", lang);
      console.log("sq", sq.toString());
      markSessionReuseUnblocked();
      markSessionIsReloading();
      navigate(`${location.pathname}?${sq.toString()}`, {
        state: location.state,
        replace: true,
      });
    },
    [navigate, location.pathname, location.search, location.state]
  );

  useEffect(() => {
    if (!langParam || !LANG_MAP[langParam]) {
      const defaultLocale = getSelectedLocale();
      onChangeLocale(defaultLocale);
    }
  }, [langParam, onChangeLocale]);

  return [LANG_MAP[langParam] || getSelectedLocale(), onChangeLocale];
}

export const LocaleContextManager = (props) => {
  const dispatch = useDispatch();
  const { currency } = useTenantContext();
  const [locale, onChangeLocale] = useLanguageQueryParam();
  const [state, setState] = useState({
    currentLocale: undefined,
    currency: "EUR",
    currencySymbol: "€",
    stringRes: {},
    languages: [
      { value: LOCALE_TYPE.GB, label: "English" },
      { value: LOCALE_TYPE.LV, label: "Latvian" },
      { value: LOCALE_TYPE.RU, label: "Russian" },
      // { value: LOCALE_TYPE.LT, label: "Lithuanian" },
    ],
    countries: [
      {
        value: "lv",
        label: "Latvia",
      },
    ],
    currencies: [{ value: "eur", label: "Euro - EUR" }],
  });

  const getStringRes = useCallback((key) => state.stringRes[key] || key, [state]);

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        currency: currency,
        currencySymbol: CURRENCY_SYMBOLS[currency],
      };
    });
  }, [currency]);

  useEffect(() => {
    if (!state.currentLocale || locale !== state.currentLocale) {
      const lang = locale.split("_")[0];
      let stringRes = {};
      localeApi
        .getStringResources(lang)
        .then((data) => {
          console.log(data.data);
          stringRes = data.data;
        })
        .catch(console.error)
        .finally(() => {
          const labels = LANG_VALUES[locale];
          const countries = COUNTRY_VALUES[locale];
          const languages = [
            { value: LOCALE_TYPE.GB, label: labels[0] },
            { value: LOCALE_TYPE.LV, label: labels[1] },
            { value: LOCALE_TYPE.RU, label: labels[2] },
            // { value: LOCALE_TYPE.LT, label: labels[3] },
          ];
          setState((prev) => {
            return {
              ...prev,
              currentLocale: locale,
              stringRes: stringRes,
              languages: languages,
              countries: [{ value: "lv", label: countries[0] }],
            };
          });
          setSelectedLocale(locale);
          dispatch(searchDataActions.changeCityDisplayLanguage(lang.toUpperCase()));
        });
    }
  }, [dispatch, locale, state.currentLocale]);

  return (
    <LocaleContext.Provider value={{ ...state, getStringRes, setLocale: onChangeLocale }}>
      {state.currentLocale && props.children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;

export const useLocaleContext = () => useContext(LocaleContext);
