import { useContext } from "react";
import Button from "src/components/UI/Button/Button";
import { ModalElementContext } from "src/context/modal-element-context";
import { CabinClassItemGroup } from "src/components/features/search/SearchForm/elements/CabinClassItemGroup/CabinClassItemGroup";
import { PassengerItemGroup } from "src/components/features/search/SearchForm/elements/PassengerItemGroup/PassengerItemGroup";
import classes from "./PassengerOptions.module.css";
import { useDeviceContext } from "src/context/device-context";
import { searchDataActions } from "src/store/search";
import { useDispatch, useSelector } from "react-redux";
import { useLocaleContext } from "src/context/locale-context";

export function PassengerOptions() {
  const dispatch = useDispatch();
  const { getStringRes } = useLocaleContext();
  const { onClose, isResultsModal, dispatchLocalState } = useContext(ModalElementContext);
  const { isMobile } = useDeviceContext();
  const tempPassengers = useSelector((state) => state.search.userInput.passengers);
  const tempCabinClass = useSelector((state) => state.search.userInput.cabinClass);

  const onFinishHandler = () => {
    if (isResultsModal) {
      dispatchLocalState({
        type: "UPDATE_PROPERTY",
        payload: { passengers: tempPassengers },
      });
      dispatchLocalState({
        type: "UPDATE_PROPERTY",
        payload: { cabinClass: tempCabinClass },
      });
    } else {
      // save temp user data to final search data context
      dispatch(searchDataActions.updatePassengers({ ...tempPassengers }));
      dispatch(searchDataActions.updateCabinClass(tempCabinClass));
    }
    onClose();
  };

  const onCancelHandler = () => {
    if (!isResultsModal) {
      // sync temp user data with final search data context
      dispatch(searchDataActions.fillUserInputPassengerOptions());
    }
    onClose();
  };

  return (
    <section className={classes.content}>
      <div className={classes.passengers}>
        <h1>{getStringRes("sf.passenger.opt.title.passengers")}</h1>
        <PassengerItemGroup />
      </div>
      <div className={classes.cabin}>
        <h2>{getStringRes("sf.passenger.opt.title.cabin")}</h2>
        <CabinClassItemGroup />
      </div>
      {isMobile && (
        <Button
          name={getStringRes("sf.passenger.opt.button.done")}
          onClick={onFinishHandler}
          fontclass={classes.fontclass}
          cornerSm
          wide
        />
      )}
      {!isMobile && (
        <div>
          <Button
            name={getStringRes("sf.passenger.opt.button.cancel")}
            onClick={onCancelHandler}
            fontclass={classes.fontclass}
            cornerSm
            secondary
          />
          <Button
            name={getStringRes("sf.passenger.opt.button.done")}
            onClick={onFinishHandler}
            fontclass={classes.fontclass}
            cornerSm
            primary
          />
        </div>
      )}
    </section>
  );
}

export default PassengerOptions;
