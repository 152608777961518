import { useLocation } from "react-router-dom";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./TopNavigation.module.css";
import { TopRightMenu } from "./elements/TopRightMenu";
import {
  LinkWithQuery,
  NavLinkWithQuery,
} from "src/components/UI/NavLinkWithQuery/NavLinkWithQuery";

const TopNavigationItems = [
  { name: "main.nav.airtickets", to: "/" },
  // { name: "main.nav.cheapflights", to: "/cheapflights" },
  // { name: "main.nav.hotels", to: "/hotels" },
  // { name: "main.nav.insurance", to: "/insurance" },
  { name: "main.nav.business", to: "/business" },
  { name: "main.nav.groups", to: "/groups" },
];

function TopNavigation() {
  const { isMobile } = useDeviceContext();
  const { getStringRes } = useLocaleContext();
  const currentpage = useLocation().pathname;
  const navbarNoBg = currentpage === "/search/results" || (!isMobile && currentpage === "/");

  return (
    <header
      id="main-app-top-nav"
      className={`${classes.menu} ${isMobile ? classes.mobile : classes.desktop} ${
        navbarNoBg ? "" : "opaque"
      } ${currentpage === "/search/results" && "navResults"}`}>
      <PaddedView>
        <nav className={classes.menuLeft}>
          <LinkWithQuery to={"/"}>
            <img
              className={classes.logo}
              alt="logo"
              src={
                `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
                `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/static/` +
                `${window.SERVER_DATA.REACT_APP_TENANT_NAME}/en/assets/images/logo.svg`
              }
            />
          </LinkWithQuery>
          {!isMobile &&
            TopNavigationItems.map((item) => (
              <NavLinkWithQuery
                key={item.name}
                to={item.to}
                className={({ isActive }) => (isActive ? classes.active : "")}>
                {getStringRes(item.name)}
              </NavLinkWithQuery>
            ))}
        </nav>
        <TopRightMenu withHeartButton={!isMobile} />
      </PaddedView>
    </header>
  );
}

export default TopNavigation;
