import classes from "./AirportSelection.module.css";
import { MobileSearchFormInput } from "../SearchFormInput/MobileSearchFormInput";
import { useLocaleContext } from "src/context/locale-context";

export function AirportSelectionMobileInput({
  userInput,
  isDeparture,
  onChange,
  onClear,
}) {
  const { getStringRes } = useLocaleContext();
  return (
    <div className={`${classes.searchInput} ${classes.container}`}>
      <h1>{getStringRes(isDeparture ? "sf.mobile.title.from" : "sf.mobile.title.to")}</h1>
      <MobileSearchFormInput
        id="select-location"
        value={userInput}
        placeholder={getStringRes("sf.input.placeholder")}
        label={getStringRes(isDeparture ? "sf.mobile.input.label.from" : "sf.mobile.input.label.to")}
        onChangeInput={onChange}
        onClear={onClear}
      />
    </div>
  );
}
