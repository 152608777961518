import moment from "moment";
import "./PagedCalendar.css";
import React, { useState } from "react";
import arrRight from "src/assets/img/calendarArrRight.svg";
import arrLeft from "src/assets/img/calendarArrLeft.svg";
import {
  secondDateIsLater,
  isSameDate,
  isWithinRange,
  isDisabled,
} from "src/utils/date-utils";
import { ALL_MONTHS_MAP, WEEKDAY_SHORT_STR_MAP } from "src/constants/string-res-map";
import { useLocaleContext } from "src/context/locale-context";

const PagedCalendar = (props) => {
  const {
    isRangeCalendar = false,
    minDate = moment(),
    maxDate = moment().add(12, "M"),
    monthFormat = "MMMM",
    yearFormat = "YYYY",
    enableYearTitle = true,
    enableMonthTitle = true,
    isSelectingEndDate = false,
  } = props;

  const [hoveredDate, setHoveredDate] = useState("");
  const dateType = props.isSpecificDateType ? (isSelectingEndDate ? "end" : "start") : "";

  const onPickDateHandler = (event, value) => {
    event && event.preventDefault();
    if (isSelectingEndDate) {
      props.onSelectEndDate(value);
    } else {
      props.onSelectStartDate(value);
    }
  };

  const setCurrentlyHoveredDate = (date) => {
    setHoveredDate(date);
  };

  return (
    <TwoMonthsView
      minDate={minDate}
      maxDate={maxDate}
      hoveredDate={hoveredDate}
      isRangeCalendar={isRangeCalendar}
      selectedStartDate={props.startDate}
      selectedEndDate={props.endDate}
      onPickDate={onPickDateHandler}
      className={`${props.className ? props.className : ""} desktop-datepicker`}
      yearFormat={yearFormat}
      monthFormat={monthFormat}
      enableYearTitle={enableYearTitle}
      enableMonthTitle={enableMonthTitle}
      onMouseover={setCurrentlyHoveredDate}
      dateType={dateType}
    />
  );
};

export default PagedCalendar;

export const TwoMonthsView = (props) => {
  const { minDate, maxDate } = props;
  const startDisplayAt = props.selectedStartDate
    ? props.selectedStartDate
    : moment(minDate, "DD/MMM/YYYY");

  const [displayMonth, setDisplayMonths] = useState([
    startDisplayAt,
    moment(startDisplayAt.clone().add(1, "M"), "DD/MMM/YYYY"),
  ]);
  const elements = [];

  const onChangeMonthHandler = (shift) => {
    if (shift === 1) {
      const nextMonthSameDay = displayMonth[1].clone().add(1, "M");
      if (secondDateIsLater(nextMonthSameDay.startOf("M"), maxDate)) {
        setDisplayMonths([displayMonth[1], nextMonthSameDay]);
      }
    } else if (shift === -1) {
      const lastMonthSameDay = displayMonth[0].clone().subtract(1, "M");
      if (
        secondDateIsLater(minDate, lastMonthSameDay.endOf("M")) ||
        isSameDate(minDate, lastMonthSameDay.endOf("M"))
      ) {
        setDisplayMonths([displayMonth[0].clone().subtract(1, "M"), displayMonth[0]]);
      }
    }
  };

  for (let i = 0; i < displayMonth.length; i++) {
    elements.push(
      <MonthCard
        key={i}
        timePeriod={displayMonth[i].clone()}
        onChangeMonth={onChangeMonthHandler}
        isFirst={i === 0}
        month={displayMonth[i]}
        {...props}
      />
    );
  }
  return <div className={props.className}>{elements}</div>;
};

export const MonthCard = (props) => {
  const timePeriod = props.timePeriod;

  return (
    <section className="month" id={timePeriod.format("MMMM-YYYY")}>
      <MonthHeader timePeriod={timePeriod} {...props} />
      <WeekdaysHeader className="weekdays" />
      <DaysInMonth timePeriod={timePeriod} {...props} />
    </section>
  );
};

export const MonthHeader = (props) => {
  const { getStringRes } = useLocaleContext();
  const allMonths = ALL_MONTHS_MAP.map((m) => getStringRes(m));

  const month = allMonths[props.timePeriod.month()];
  const year = props.timePeriod.format(props.yearFormat);
  const buttonClass = props.isFirst ? "previous" : "next";
  const buttonIcon = props.isFirst ? arrLeft : arrRight;

  return (
    <div className={"month-header " + buttonClass}>
      <button
        className={buttonClass}
        onClick={() => props.onChangeMonth(props.isFirst ? -1 : 1)}>
        <img alt="" src={buttonIcon} />
      </button>
      <p className="month-title">
        {props.enableYearTitle ? <span>{year}</span> : null}
        {props.enableMonthTitle ? month : null}
      </p>
    </div>
  );
};

export const WeekdaysHeader = (props) => {
  const { getStringRes } = useLocaleContext();
  const weekdays = WEEKDAY_SHORT_STR_MAP.map((wkd) => getStringRes(wkd));
  return (
    <ul className="weekdays">
      <li key={"Monday"}>{weekdays[0]}</li>
      <li key={"Tuesday"}>{weekdays[1]}</li>
      <li key={"Wednesday"}>{weekdays[2]}</li>
      <li key={"Thursday"}>{weekdays[3]}</li>
      <li key={"Friday"}>{weekdays[4]}</li>
      <li key={"Saturday"}>{weekdays[5]}</li>
      <li key={"Sunday"}>{weekdays[6]}</li>
    </ul>
  );
};

export const SingleDay = ({
  isActive,
  isToday,
  isRange,
  handleClick,
  currentValue,
  isDisabled,
  onMouseover,
  dateType,
  i,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseoverHandler = () => {
    setIsHovered(true);
    onMouseover(currentValue);
  };

  const className = [];
  if (dateType) {
    if (dateType === "end") {
    } else if (dateType === "start") {
    }
  } else {
    if (isRange) className.push("range");
    if ((isActive && !isRange) || isHovered) className.push("active");
  }

  if (isDisabled) className.push("disabled");
  if (isToday) className.push("today");

  return (
    <li
      className={className.join(" ")}
      key={i}
      onDoubleClick={(e) => handleClick(e, currentValue)}
      onClick={(e) => handleClick(e, currentValue)}
      onMouseOver={onMouseoverHandler}
      onMouseOut={() => setIsHovered(false)}>
      <span>{currentValue.date()}</span>
    </li>
  );
};

export const DaysInMonth = ({
  isRangeCalendar,
  timePeriod,
  selectedStartDate,
  selectedEndDate,
  onPickDate,
  minDate,
  maxDate,
  hoveredDate,
  onMouseover,
  dateType,
}) => {
  const daysInMonth = timePeriod.daysInMonth();
  const dayOne = timePeriod.startOf("month");
  //our weekdat starts visually on Monday & handle day starting on Sunday (day=0)
  const monthStartWeekdayOffset = dayOne.day() > 0 ? dayOne.day() - 1 : 6;

  const compareDates = (calendarDay) => {
    let isSameAsStart, isSameAsEnd;
    if (selectedStartDate) {
      isSameAsStart = isSameDate(calendarDay, selectedStartDate);
    }
    if (isRangeCalendar && selectedEndDate) {
      isSameAsEnd = isSameDate(calendarDay, selectedEndDate);
    }
    return isSameAsStart || isSameAsEnd;
  };

  const renderDaysInMonth = () => {
    let elements = [];
    let calendarDay = moment(timePeriod, "DD/MMM/YYYY");
    for (let i = 1; i <= daysInMonth; i++) {
      const rangeCond1 = isWithinRange(
        selectedStartDate,
        calendarDay.clone(),
        selectedEndDate
      );

      const rangeCond2 =
        (!hoveredDate && rangeCond1) ||
        isWithinRange(selectedStartDate, calendarDay.clone(), hoveredDate);

      const rangeCond3 =
        (!hoveredDate && rangeCond1) ||
        isWithinRange(hoveredDate, calendarDay.clone(), selectedEndDate);

      let inRange;
      let isActive = compareDates(calendarDay.clone());
      if (dateType === "end") {
        inRange = rangeCond2;
        isActive =
          (isActive && !hoveredDate) ||
          (hoveredDate && isSameDate(selectedStartDate, calendarDay)) ||
          (hoveredDate && isSameDate(hoveredDate, calendarDay));
      } else if (dateType === "start") {
        inRange = rangeCond3;
        isActive =
          (isActive && !hoveredDate) ||
          (hoveredDate && isSameDate(selectedEndDate, calendarDay)) ||
          (hoveredDate && isSameDate(hoveredDate, calendarDay));
      } else {
        inRange = isRangeCalendar && (rangeCond1 || rangeCond2);
      }

      elements.push(
        <SingleDay
          isToday={isSameDate(moment(), calendarDay.clone())}
          isRange={inRange}
          isActive={isActive}
          isDisabled={isDisabled(minDate, calendarDay.clone(), maxDate)}
          handleClick={onPickDate}
          currentValue={calendarDay.clone()}
          onMouseover={onMouseover}
          key={i}
        />
      );
      calendarDay = calendarDay.add(1, "days");
    }
    return elements;
  };

  const resetHoverDate = () => {
    if (hoveredDate) {
      let date;
      if (dateType === "start") {
        date = selectedStartDate;
      } else {
        date = selectedEndDate;
      }
      onMouseover(date);
    }
  };

  const renderCalendarShift = () => {
    let elements = [];
    for (let i = 0; i < monthStartWeekdayOffset; i++) {
      elements.push(
        <li
          onMouseEnter={() => {
            resetHoverDate();
          }}
          className="visible-hidden"
          key={i}
        />
      );
    }
    return elements;
  };

  const calendarShift = renderCalendarShift();
  const calendarDays = renderDaysInMonth();

  return (
    <ul
      className="date"
      onMouseLeave={() => {
        resetHoverDate();
      }}>
      {calendarShift}
      {calendarDays}
    </ul>
  );
};
