import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import BackButton from "src/components/UI/BackButton/BackButton";
import Button from "src/components/UI/Button/Button";
import { PassengerIcon } from "src/components/UI/Icon/PassengerIcon";
import { BOOKING_STEP } from "src/constants";
import { WEEKDAY_SHORT_STR_MAP } from "src/constants/string-res-map";
import { useBookingFlow } from "src/context/booking-flow-context";
import { useLocaleContext } from "src/context/locale-context";
import { useModalContext } from "src/context/modal-stack-context";
import { MODAL_TYPE } from "src/constants/modal-definitions";
import { useTenantContext } from "src/context/tenant-context";
import { selectAllPassengers, selectTravelPackage } from "src/store/booking";
import { getFlightDateFormatted, getWeekdayFromDate } from "src/utils/date-utils";
import { getFirstFlightChain, getLastFlightChain } from "src/utils/results-utils";
import classes from "./MobileBookingHeader.module.css";

function getBackdropColor(path) {
  switch (path) {
    case BOOKING_STEP.insuranceSelection.path:
      return "var(--primary-1)";
    case BOOKING_STEP.extraServicesSelection.path:
      return "#00A58E";
    default:
      return null;
  }
}

function MobileBookingHeader() {
  const { getStringRes } = useLocaleContext();
  const { shortDateFormat } = useTenantContext();
  const { goToPrevBookingPage } = useBookingFlow();
  const { openModal } = useModalContext();
  const match = useMatch("/booking/:bookingStep");
  const { bookingStep } = match.params;

  const travelPackage = useSelector(selectTravelPackage);
  const passengers = useSelector(selectAllPassengers);
  const isReturn = travelPackage.segments_direction.length === 2;
  const firstChain = getFirstFlightChain(travelPackage);
  const lastChain = getLastFlightChain(travelPackage);

  const weekdayNumStart = getWeekdayFromDate(firstChain[0].dep.date, "DD.MM.YYYY");
  const weekdayNumEnd = getWeekdayFromDate(lastChain[0].dep.date, "DD.MM.YYYY");

  const dateStart = getFlightDateFormatted(firstChain[0].dep.date, shortDateFormat);
  const dateEnd = getFlightDateFormatted(lastChain[0].dep.date, shortDateFormat);

  const dates =
    `${getStringRes(WEEKDAY_SHORT_STR_MAP[weekdayNumStart])}, ${dateStart} ` +
    (isReturn ? `- ${getStringRes(WEEKDAY_SHORT_STR_MAP[weekdayNumEnd])}, ${dateEnd}` : "");

  const onBackToPrevStep = () => {
    const currStep = Object.entries(BOOKING_STEP).find((e) => e[1].path === bookingStep);
    if (currStep) {
      const pageId = currStep[0];
      goToPrevBookingPage(pageId, { source: "booking" });
    }
  };

  const onGoBack = () => {
    onBackToPrevStep();
  };

  const onOpenDetails = () => {
    openModal(MODAL_TYPE.flightDetails, { item: travelPackage, hideContinue: true });
  };

  const hideSummary = [
    BOOKING_STEP.insuranceSelection.path,
    BOOKING_STEP.extraServicesSelection.path,
  ].includes(bookingStep);

  if (!hideSummary) {
    return (
      <div className={classes.container}>
        <div className={classes.prev}>
          <BackButton onClick={onGoBack} />
        </div>
        <div className={classes.summary}>
          <div className={classes.cities}>{`${firstChain[0].dep.city.title} - ${
            isReturn
              ? firstChain[firstChain.length - 1].arr.city.title
              : lastChain[lastChain.length - 1].arr.city.title
          }`}</div>
          <div className={classes.dates}>
            {dates}
            <span>
              <PassengerIcon className={classes.paxIcon} />
              {passengers.length}
            </span>
          </div>
        </div>
        <div className={classes.details}>
          <Button
            name={getStringRes("button.general.details")}
            secondary
            onClick={onOpenDetails}
          />
        </div>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div className={classes.backdrop}>
          <svg
            width="390"
            height="333"
            viewBox="0 0 390 333"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <ellipse
              cx="200"
              cy="97"
              rx="335.5"
              ry="236"
              fill={getBackdropColor(bookingStep)}
            />
          </svg>
        </div>
        <div className={`${classes.container} ${classes.minimal}`}>
          <div className={classes.prev}>
            <BackButton invertColor onClick={onGoBack} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MobileBookingHeader;
