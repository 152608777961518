import classes from "./MultiSearchForm.module.css";
import { Fragment, useContext } from "react";
import { useDispatch } from "react-redux";
import { searchDataActions } from "src/store/search";
import { CABIN_CLASS_MAP } from "src/constants";
import { MultiSearchFormItem } from "./MultiSearchFormItem";
import SearchFormText from "src/components/features/search/SearchForm/elements/SearchFormText/SearchFormText";
import { calculateTotalPassengers, customLog } from "src/utils/utils";
import Button from "src/components/UI/Button/Button";
import { ModalElementContext } from "src/context/modal-element-context";
import { useLocaleContext } from "src/context/locale-context";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigation } from "react-router-dom";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { getPluralizedCaseCode } from "src/utils/trip-utils";

const MAX_TRIP_NUM = 6;

function MobileMultiSearchForm({
  onSearchFormClick,
  onStartSearch,
  isButtonEnabled,
  isResultsPage,
  searchData,
  dispatchSFState,
}) {
  const dispatch = useDispatch();
  const { currentLocale, getStringRes } = useLocaleContext();
  const navigationState = useNavigation().state;
  const lang = currentLocale.split("_")[0].toUpperCase();
  const { onClose } = useContext(ModalElementContext);
  const trips = searchData.trips;
  const canAddTrip = trips.length < MAX_TRIP_NUM;
  const passengerNum = calculateTotalPassengers(searchData.passengers);

  const cabinClass = searchData.cabinClass
    ? getStringRes(CABIN_CLASS_MAP[searchData.cabinClass]).toLowerCase()
    : getStringRes("sf.field.cabin_class_any");

  const passengerValue =
    `${passengerNum} ` +
    getStringRes(`sf.field.passenger_${getPluralizedCaseCode(passengerNum)}`) +
    ", " +
    cabinClass;

  const onAddFormHandler = () => {
    if (isResultsPage) {
      const lastTrip = searchData.trips[searchData.trips.length - 1];
      const newId = nanoid();
      dispatch(
        searchDataActions.addUserInputTrip({
          tripId: newId,
          city: lastTrip.destLocation.city,
          iata: lastTrip.destLocation.iata,
        })
      );
      dispatchSFState({ type: "ADD_TRIP", id: newId });
    } else {
      customLog("rerender on onAddFormHandler DISPATCH");
      dispatch(searchDataActions.addTrip());
    }
  };

  const onRemoveFormHandler = (tripId) => {
    if (isResultsPage) {
      dispatch(searchDataActions.removeUserInputTrip({ tripId: tripId }));
      dispatchSFState({ type: "REMOVE_TRIP", id: tripId });
    } else {
      customLog("rerender on onRemoveFormHandler DISPATCH");
      dispatch(searchDataActions.removeTrip(tripId));
    }
  };

  const onSwitchDirectionHandler = (tripId) => {
    const lang = currentLocale.split("_")[0].toUpperCase();
    if (isResultsPage) {
      const idx = searchData.trips.findIndex((t) => t.id === tripId);
      const switchedLocations = {
        srcLocation: trips[idx].destLocation,
        destLocation: trips[idx].srcLocation,
      };
      dispatchSFState({ type: "UPDATE_TRIP", payload: switchedLocations, id: tripId });
      dispatch(
        searchDataActions.updateUserInputTrip({
          tripId: tripId,
          input: {
            srcLocation: switchedLocations.srcLocation.city,
            srcIata: switchedLocations.srcLocation.iata,
            destLocation: switchedLocations.destLocation.city,
            destIata: switchedLocations.destLocation.iata,
          },
        })
      );
    } else {
      customLog("rerender on onSwitchDirectionHandler DISPATCH");
      dispatch(searchDataActions.switchDestWithSrcLocation({ id: tripId, lang }));
    }
  };

  return (
    <Fragment>
      <form name="main-search-form-multi" className={classes.container}>
        {trips.map((trip, i) => (
          <MultiSearchFormItem
            key={trip.id}
            trip={trip}
            tripNum={i + 1}
            onRemove={onRemoveFormHandler}
            onSwitchDirection={onSwitchDirectionHandler}
            onInputClick={onSearchFormClick}
          />
        ))}
        <SearchFormText
          id="passengers"
          className={classes.passengers}
          value={passengerValue}
          label={getStringRes("sf.field.passengers")}
          onClick={() => onSearchFormClick("passengers")}
        />
      </form>
      {canAddTrip && (
        <button type="button" onClick={onAddFormHandler}>
          {"+ " + getStringRes("sf.multi.button.add")}
        </button>
      )}
      <div className={classes.buttonRow}>
        {isResultsPage && (
          <button
            className={classes.back}
            type="button"
            onClick={() => {
              dispatch(searchDataActions.fillUserInputPassengerOptions());
              dispatch(searchDataActions.fillUserInputTrips(lang));
              onClose();
            }}>
            {getStringRes("sf.mobile.button.back")}
          </button>
        )}
        <Button
          disabled={!isButtonEnabled || navigationState === "loading"}
          name={
            !isResultsPage && navigationState === "loading" ? (
              <DotPulseLoader opacity={0.2} color="#000" />
            ) : (
              getStringRes("sf.button.search")
            )
          }
          fontclass={classes.fontclass}
          opts={{ minHeight: isResultsPage ? "3rem" : "3.5rem" }}
          onClick={onStartSearch}
          {...(!isResultsPage || isButtonEnabled ? { primary: true } : { secondary: true })}
          wide={!isResultsPage}
          shadow={!isResultsPage}
        />
      </div>
    </Fragment>
  );
}

export default MobileMultiSearchForm;
