import React, { Suspense, lazy } from "react";
import { createBrowserRouter, redirect } from "react-router-dom";
import { AuthContextProvider } from "./context/auth-context";
import RootLayout from "./pages/Root";
import { loader as bookingLoader } from "./pages/loaders/BookingPageLoader";
import { loader as orderOkLoader } from "./pages/loaders/OrderCompleteLoader";
import { loader as orderReceivedLoader } from "./pages/loaders/OrderReceivedLoader";
import { loader as paymentPageLoader } from "./pages/loaders/PaymentByLinkLoader";
import { loader as resultsLoader } from "./pages/loaders/SearchResultsLoader";
import { loader as dashboardLoader } from "./pages/loaders/DashboardLoader";
import { loader as userTripsLoader } from "./pages/loaders/UserTripsLoader";
import { loader as userTripsDetailsLoader } from "./pages/loaders/UserTripDetailsLoader";
import { loader as userInvoicesLoader } from "./pages/loaders/UserInvoicesLoader";

import TemporarilyUnavailable from "./pages/TempUnavailable";
import AboutUs from "./pages/AboutUs";
import WithNavigationLayout from "./pages/WithNavigationLayout";
import Hotels from "./pages/Hotels";
import CheapFlights from "./pages/CheapFlights";
import HomePage from "./pages/HomePage";
import TravelInsurance from "./pages/TravelInsurance";
import ErrorBoundary from "./pages/ErrorBoundary";
import PageNotFound from "./pages/PageNotFound";
import { auth } from "./services/firebase";
import { AuthRoute } from "./pages/AuthRoute";
import GroupBookingRequestSent from "./pages/GroupBookingRequestSent";

// Lazy-loaded components
const BookingFlowController = lazy(() => import("./context/booking-flow-context"));
const BookingCancelled = lazy(() => import("./pages/BookingCancelled"));
const BookingFlowPage = lazy(() => import("./pages/BookingFlowPage"));
const BookingLayoutWrapper = lazy(() => import("./pages/BookingLayoutWrapper"));
const BookingPaymentByLink = lazy(() => import("./pages/BookingPaymentByLink"));
const OrderComplete = lazy(() => import("./pages/OrderComplete"));
const OfflineOrderReceived = lazy(() => import("./pages/OfflineOrderConfirmed"));
const OrderError = lazy(() => import("./pages/OrderError"));
const SearchResults = lazy(() => import("./pages/SearchResults"));
const PostOrderWrapper = lazy(() => import("./pages/PostOrderWrapper"));

const InfoPageLayout = lazy(() => import("./pages/InfoPageLayout"));
const FAQPage = lazy(() => import("./pages/FAQPage"));
const TermsOfService = lazy(() => import("./pages/info/TermsOfService"));
const PrivacyPolicy = lazy(() => import("./pages/info/PrivacyPolicy"));
const CancellationPolicy = lazy(() => import("./pages/info/CancellationPolicy"));
const GuaranteeRules = lazy(() => import("./pages/info/GuaranteeRules"));

const DashboardLayout = lazy(() => import("./pages/DashboardLayout"));
const UserTrips = lazy(() => import("./pages/dashboard/UserTrips"));
const UserTripDetails = lazy(() => import("./pages/dashboard/UserTripDetails"));
const UserInvoices = lazy(() => import("./pages/dashboard/UserInvoices"));

const BusinessBookingPage = lazy(() => import("./pages/BusinessBooking"));
const GroupBookingPage = lazy(() => import("./pages/GroupBooking"));

const LoadingFallback = () => null;

const withAuthLoader = (loader) => {
  return async (args) => {
    const route = new URL(args.request.url);
    const path = route.pathname;
    const user = await new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      });
    });
    console.log(user);
    console.log(path);
    if (!user) return redirect("/");

    if (args) args.user = user;
    return loader(args);
  };
};

const router = createBrowserRouter([
  {
    element: <AuthContextProvider />,
    children: [
      {
        path: "/",
        element: <RootLayout />,
        errorElement: <ErrorBoundary />,
        loader: async () => {
          // Check if localStorage and sessionStorage are available
          const storageAvailable = (type) => {
            let storage;
            try {
              storage = window[type];
              const test = "__storage_test__";
              storage.setItem(test, test);
              storage.removeItem(test);
              return true;
            } catch (e) {
              return false;
            }
          };

          const isLocalStorageAvailable = storageAvailable("localStorage");
          const isSessionStorageAvailable = storageAvailable("sessionStorage");

          return { isLocalStorageAvailable, isSessionStorageAvailable };
        },
        children: [
          {
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <WithNavigationLayout />
              </Suspense>
            ),
            children: [
              { index: true, element: <HomePage /> },
              { path: "cheapflights", element: <CheapFlights /> },
              { path: "hotels", element: <Hotels /> },
              { path: "insurance", element: <TravelInsurance /> },
              {
                path: "business",
                element: <BusinessBookingPage />,
                children: [{ path: "sent", element: <GroupBookingRequestSent /> }],
              },
              {
                path: "groups",
                element: <GroupBookingPage />,
                children: [{ path: "sent", element: <GroupBookingRequestSent /> }],
              },
              {
                path: "search/results",
                element: <SearchResults />,
                loader: resultsLoader,
              },
              {
                path: "dashboard",
                element: <AuthRoute component={DashboardLayout} />,
                loader: withAuthLoader(dashboardLoader),
                children: [
                  {
                    path: "trips",
                    element: <AuthRoute component={UserTrips} />,
                    loader: withAuthLoader(userTripsLoader),
                  },
                  {
                    path: "trips/:orderId",
                    element: <AuthRoute component={UserTripDetails} />,
                    loader: withAuthLoader(userTripsDetailsLoader),
                  },
                  { path: "passengers", element: <TemporarilyUnavailable /> },
                  {
                    path: "invoices",
                    element: <AuthRoute component={UserInvoices} />,
                    loader: withAuthLoader(userInvoicesLoader),
                  },
                  { path: "alerts", element: <TemporarilyUnavailable /> },
                  { path: "referral", element: <TemporarilyUnavailable /> },
                  { path: "preferences", element: <TemporarilyUnavailable /> },
                  { path: "support", element: <TemporarilyUnavailable /> },
                ],
              },
              {
                path: "info",
                element: <InfoPageLayout />,
                children: [
                  { path: "terms-of-service", element: <TermsOfService /> },
                  { path: "privacy-policy", element: <PrivacyPolicy /> },
                  { path: "cancellation-policy", element: <CancellationPolicy /> },
                  { path: "guarantee-rules", element: <GuaranteeRules /> },
                  { path: "faq", element: <FAQPage /> },
                ],
              },
              {
                path: "about-us",
                element: <InfoPageLayout />,
                children: [{ path: "", element: <AboutUs /> }],
              },
              { path: "*", element: <PageNotFound /> },
            ],
          },
          {
            path: "booking/cancelled",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <BookingCancelled />
              </Suspense>
            ),
          },
          {
            path: "booking/payment",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <BookingPaymentByLink />
              </Suspense>
            ),
            loader: paymentPageLoader,
          },
          {
            id: "bookingRouteController",
            path: "booking",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <BookingFlowController />
              </Suspense>
            ),
            loader: bookingLoader,
            children: [
              {
                element: <BookingLayoutWrapper />,
                children: [{ path: ":bookingStep", element: <BookingFlowPage /> }],
              },
            ],
          },
          {
            path: "order",
            element: (
              <Suspense fallback={<LoadingFallback />}>
                <PostOrderWrapper />
              </Suspense>
            ),
            children: [
              {
                path: ":orderId/thankyou",
                element: <OrderComplete />,
                loader: orderOkLoader,
              },
              {
                path: ":orderId/error",
                element: <OrderError />,
              },
              {
                path: ":orderId/confirmation",
                element: <OfflineOrderReceived />,
                loader: orderReceivedLoader,
              },
              {
                path: ":orderId/complete",
                element: <div>this order has been paid</div>,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
